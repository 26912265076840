<template>
  <div class="block-input">
    <InputLabel :label="label" :required="required" v-if="label" />
    <input
      :type="type"
      :name="name"
      :placeholder="placeholder || label"
      :required="required"
      :value="value"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      @change="$emit('change', $event)"
      @input="input"
    />
  </div>
</template>

<script>
export default {
  props: ["name", "type", "label", "required", "value", "placeholder", "readonly", "disabled", "autocomplete"],
  methods: {
    input: function($event) {
      this.$emit("input", { value: $event.target.value });
    },
  },
};
</script>
